import { createSelector } from '@reduxjs/toolkit'

import type { ReduxState } from 'store'

const getSnackbar = (state: ReduxState) => state.snackbar
export const getSnackbarVisibilitySelector = createSelector(getSnackbar, (snackbar) => snackbar.visible)
export const getSnackbarVariantSelector = createSelector(getSnackbar, (snackbar) => snackbar.variant)
export const getSnackbarMessageSelector = createSelector(getSnackbar, (snackbar) => snackbar.message)
export const getSnackbarActionButtonSelector = createSelector(getSnackbar, (snackbar) => snackbar.action)
export const getSnackbarMessageAlignSelector = createSelector(getSnackbar, (snackbar) => snackbar.messageAlign)
export const getPositionVerticalSelector = createSelector(getSnackbar, (snackbar) => snackbar.positionVertical)
export const getPositionHorizontalSelector = createSelector(getSnackbar, (snackbar) => snackbar.positionHorizontal)
export const getSnackbarComponentPropsSelector = createSelector(getSnackbar, (snackbar) => snackbar.componentProps)
export const getSnackbarAutoHideSelector = createSelector(getSnackbar, (snackbar) => snackbar.autoHide)
export const getSnackbarErrorDetailsSelector = createSelector(getSnackbar, (snackbar) => snackbar.errorDetails)
export const getSnackbarCloseWhenPathChangedSelector = createSelector(
  getSnackbar,
  (snackbar) => snackbar.closeWhenPathChanged
)
