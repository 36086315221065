import { isBrowser } from '@patrianna/shared-utils/helpers'

export const SOCIAL_REGISTRATION = 'SOCIAL_REGISTRATION'
export const GOOGLE_REG_VALUES = 'GOOGLE_REG_VALUES'
export const FB_REG_VALUES = 'FB_REG_VALUES'
export const MANUAL_REG_VALUES = 'MANUAL_REG_VALUES'
export const LAST_ACTIVE_REG_STEP = 'LAST_ACTIVE_REG_STEP'
export const DISABLED_REG_STEPS = 'DISABLED_REG_STEPS'
export const TERMS_ACCEPTED_REG = 'TERMS_ACCEPTED_REG'
export const REWARD_CODE = 'REWARD_CODE'
export const IN_LOGOUT_PROCESS = 'IN_LOGOUT_PROCESS'
export const REFERRED_BY = 'referred_by'
export const IS_OPENED_PAYMENT_MODALS = 'IS_OPENED_PAYMENT_MODALS'

export type SessionStorageKey =
  | typeof SOCIAL_REGISTRATION
  | typeof GOOGLE_REG_VALUES
  | typeof FB_REG_VALUES
  | typeof MANUAL_REG_VALUES
  | typeof LAST_ACTIVE_REG_STEP
  | typeof DISABLED_REG_STEPS
  | typeof TERMS_ACCEPTED_REG
  | typeof REWARD_CODE
  | typeof REFERRED_BY
  | typeof IN_LOGOUT_PROCESS
  | typeof IS_OPENED_PAYMENT_MODALS

export const setDataToSessionStorage = (key: SessionStorageKey, value: object | string | boolean): void => {
  try {
    if (isBrowser()) {
      window.sessionStorage.setItem(key, JSON.stringify(value))
    }
  } catch (err) {
    console.log(err)
  }
}

export const getDataFromSessionStorage = <T>(key: SessionStorageKey): T | null => {
  try {
    if (!isBrowser()) {
      return null
    }

    const data = window.sessionStorage.getItem(key)

    if (!data || data === 'undefined') {
      return null
    }

    const parsedData = JSON.parse(data) as T

    return parsedData
  } catch (err) {
    console.log(err)

    return null
  }
}

export const removeDataFromSessionStorage = (key: SessionStorageKey): void => {
  try {
    if (isBrowser()) {
      window.sessionStorage.removeItem(key)
    }
  } catch (err) {
    console.log(err)
  }
}
