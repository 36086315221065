import { isBrowser } from '@patrianna/shared-utils/helpers'

import type { ReduxState, TypedThunk } from 'store'
import { getUserIdSelector } from 'store/modules/user/selectors'

export const LOGGED_OUT_ID = 'logged-out'
export const IS_QUICK_START_OPENED = 'IS_QUICK_START_OPENED'
export const SAVED_UPCOMMING_GAMES = 'SAVED_UPCOMMING_GAMES'
export const LAST_DIALOG_OPEN = 'LAST_DIALOG_OPEN'
export const DELAYED_DIALOGS = 'DELAYED_DIALOGS'
export const LS_AUTH_TYPE = 'authType'
export const IS_USER_REGISTERED = 'IS_USER_REGISTERED'
export const IS_USER_OPENED_SC_RULES = 'IS_USER_OPENED_SC_RULES'
export const TABLE_GAMES_BANNERS_COUNTER = 'TABLE_GAMES_BANNERS_COUNTER'

export const CLOSE_AUTO_PWA_DIALOG = 'CLOSE_AUTO_PWA_DIALOG'
export const HIDE_INSTALL_PWA_BUTTON = 'HIDE_INSTALL_PWA_BUTTON'
export const INVITE_FRIENDS_HIDDEN = 'INVITE_FRIENDS_HIDDEN'
export const DAILY_REWARD_MODAL_LAST_TIME_SHOWN = 'DAILY_REWARD_MODAL_LAST_TIME_SHOWN'
export const OPT_IN_NOW_DIALOG_SHOWN = 'OPT_IN_NOW_DIALOG_SHOWN_V8'
export const IS_SIGN_UP_SESSION = 'IS_SIGN_UP_SESSION'
export const SESSION_ID = 'SESSION_ID'
export const USER_ID = 'USER_ID'
export const IS_LIVE_CASINO_SHOWN = 'IS_LIVE_CASINO_SHOWN'
export const WELCOME_MESSAGE = 'WELCOME_MESSAGE'
export const CAPTCHA_DISABLED = 'CAPTCHA_DISABLED'

export type LocalStorageKeys =
  | 'howToWinModal'
  | 'userEmail'
  | 'REFERRER'
  | 'QUERY'
  | 'AD_FIRST_TIME_OPENED'
  | 'ORDERED_OFFER'
  | 'REDEEM_PROVIDER'
  | 'NOT_ENOUGH_FUNDS'
  | typeof SESSION_ID
  | typeof USER_ID
  | 'WELCOME_EMAIL_DIALOG_TIMER_ID'
  | 'FIRST_UTM'
  | 'LAST_UTM'
  | 'IS_USER_VISITED_FB'
  | 'IS_USER_VISITED_INSTAGRAM'
  | 'LOW_COINS_DIALOG'
  | typeof DELAYED_DIALOGS
  | typeof LAST_DIALOG_OPEN
  | typeof SAVED_UPCOMMING_GAMES
  | typeof IS_QUICK_START_OPENED
  | typeof LS_AUTH_TYPE
  | typeof IS_USER_REGISTERED
  | typeof CLOSE_AUTO_PWA_DIALOG
  | typeof HIDE_INSTALL_PWA_BUTTON
  | typeof IS_USER_OPENED_SC_RULES
  | typeof TABLE_GAMES_BANNERS_COUNTER
  | typeof INVITE_FRIENDS_HIDDEN
  | typeof DAILY_REWARD_MODAL_LAST_TIME_SHOWN
  | typeof OPT_IN_NOW_DIALOG_SHOWN
  | typeof IS_SIGN_UP_SESSION
  | typeof IS_LIVE_CASINO_SHOWN
  | typeof WELCOME_MESSAGE
  | typeof CAPTCHA_DISABLED

const generateLsKey = (key: LocalStorageKeys, userId: string | null | undefined, personalized: boolean) => {
  const personalizedKey = userId ? `${key}-${userId}` : `${key}-${LOGGED_OUT_ID}`

  return personalized ? personalizedKey : key
}

// returns userID from localStorage or from redux store
function getUserId(state: ReduxState) {
  if (!isBrowser()) {
    return null
  }

  try {
    const userIdFromStore = getUserIdSelector(state)

    return userIdFromStore
  } catch (err) {
    // eslint-disable-next-line
    console.log(err)

    return null
  }
}

export const setDataToLocalStorage =
  (key: LocalStorageKeys, value?: object | string | boolean, personalized = true): TypedThunk =>
  (_, getState: () => ReduxState) => {
    const userId = getUserId(getState())
    const lsKey = generateLsKey(key, userId, personalized)

    try {
      window?.localStorage.setItem(lsKey, JSON.stringify(value))
    } catch (err) {
      // eslint-disable-next-line
      console.log(err)
    }
  }

export const removeDataFromLocalStorage =
  (key: LocalStorageKeys, personalized = true): TypedThunk =>
  (_, getState: () => ReduxState) => {
    const userId = getUserId(getState())
    const lsKey = generateLsKey(key, userId, personalized)

    try {
      window?.localStorage.removeItem(lsKey)
    } catch (err) {
      // eslint-disable-next-line
      console.log(err)
    }
  }

export const getItemFormLocalStorageSelector = (state: ReduxState, key: LocalStorageKeys, personalized = true) => {
  try {
    if (typeof window === 'undefined') {
      return null
    }

    const userId = getUserId(state)
    const lsKey = generateLsKey(key, userId, personalized)

    const data = window.localStorage.getItem(lsKey)

    if (data && data !== 'undefined') {
      const result = JSON.parse(data)

      return result
    }

    return null
  } catch (err) {
    // eslint-disable-next-line
    console.log(err)

    return null
  }
}
