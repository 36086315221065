import allCountryConfigs from './config'
import { DEFAULT_COUNTRY_CODE } from './constants'

type ConfigItem = {
  enabled: boolean
  title: string
  label?: string
  numbers?: string
}

type pageContentFeature = {
  title: string
  url: string
}

type TermsContentFeature = {
  title: string
  ruleName: 'TC-GC' | 'TC-GC-NON-US'
}

type PrivacyPageContentFeature = {
  title: string
  url: 'PP-GC' | 'PP-GC-NON-US'
}

type FormStatesValueFeature = {
  title: string
  country: string
}

export type CountryConfig = {
  title: string
  storeBenefits: ConfigItem
  liveChatFeature: ConfigItem
  phoneSupportFeature: ConfigItem
  profilePageFeature: ConfigItem
  goldCoinsPageFeature: ConfigItem
  socialLinksFeature: ConfigItem
  extraCoinsBannerFeature: ConfigItem
  loyaltyProgramFeature: ConfigItem
  referFriendFeature: ConfigItem
  helpCentreFeature: ConfigItem
  paymentSupportNumbersPrimaryFeature: ConfigItem
  paymentSupportNumbersSecondaryFeature: ConfigItem
  termsContentFeature: TermsContentFeature
  levelUpHandlerFeature: ConfigItem
  vipWeeklyRewardFeature: ConfigItem
  lowOnCoinsFeature: ConfigItem
  exclusiveCategoryFeature: ConfigItem
  aboutPageContentFeature: pageContentFeature
  exclusiveGcPageFeature: ConfigItem
  privacyPageContentFeature: PrivacyPageContentFeature
  privacyPageCaliforniaContentFeature: pageContentFeature
  specialOffersFeature: ConfigItem
  freeAddOnsFeature: ConfigItem
  responsiblePlayContentFeature: pageContentFeature
  bannersFeature: ConfigItem
  dailyRewardFeature: ConfigItem
  footerGamesMenuFeature: ConfigItem
  stateResidencyValidatorFeature: ConfigItem
  countryValidatorFeature: ConfigItem
  formStatesValueFeature: FormStatesValueFeature
  formSelectFieldFeature: ConfigItem
  transactionHistoryPageFeature: ConfigItem
}

export type Country = CountryConfig & {
  countryCode: string
}

/**
 * This is a function return config with options specific to the user country by country code.
 * Can be used on both client and server component.
 *
 * @param {string} countryCode - country code in ISO 3166 format. For example: US, NZ, UA
 * @return {Object} CountryConfig
 *
 * @example
 *
 *     const { socialLinksFeature } = getCountryConfig('US')
 */
export default function getCountryConfig(countryCode: string): CountryConfig {
  const hasCountryConfig = Object.prototype.hasOwnProperty.call(allCountryConfigs, countryCode)
  const multiCountryConfigKey = hasCountryConfig ? countryCode : 'no-country'
  const countryConfigKey = process.env.MULTI_COUNTRY_SUPPORT ? multiCountryConfigKey : DEFAULT_COUNTRY_CODE

  const userCountryConfig = allCountryConfigs[countryConfigKey]

  return userCountryConfig
}
