import { createSelector } from '@reduxjs/toolkit'

import type { ReduxState } from 'store'
import { getSnackbarErrorDetailsSelector } from 'store/modules/snackbar/selectors'
import {
  getItemFormLocalStorageSelector,
  DAILY_REWARD_MODAL_LAST_TIME_SHOWN,
  IS_SIGN_UP_SESSION,
} from 'utils/localStorage'
import { IS_OPENED_PAYMENT_MODALS, setDataToSessionStorage } from 'utils/sessionStorage'

const getDialog = (state: ReduxState) => state.dialog
export const getDialogLightBackdropModeSelector = createSelector(getDialog, (dialog) => dialog.lightBackdropMode)

export const getDialogStackSelector = createSelector(getDialog, (dialog) => dialog.stack)

export const getDialogVisibilitySelector = createSelector(getDialogStackSelector, (stack) => stack.length > 0)

export const getActiveDialogProps = createSelector(getDialogStackSelector, (stack) => {
  return stack[stack.length - 1]
})

export const getDialogVisibilityByNameSelector = createSelector(
  getDialogStackSelector,
  (_: ReduxState, modalName: string) => modalName,
  (dialogsStack, modalName) => {
    const index = dialogsStack.findIndex((dialog) => dialog.modalName === modalName)

    return index !== -1
  }
)

export const getPaymentDialogsOpenedSelector = createSelector(getDialogStackSelector, (dialogsStack) => {
  const modalNames = [
    'PAYMENT_DIALOG',
    'PAYMENT_CVV_DIALOG',
    'PAYMENT_3DS_DIALOG',
    'PAYMENT_ERROR_DIALOG',
    'ORDER_CONFIRMATION_MESSAGE',
  ]
  const index = dialogsStack.findIndex((dialog) => modalNames.includes(dialog.modalName))
  const isModalOpened = index !== -1

  setDataToSessionStorage(IS_OPENED_PAYMENT_MODALS, (+isModalOpened)?.toString())

  return isModalOpened
})

export const getPaymentErrorExistSelector = createSelector(getSnackbarErrorDetailsSelector, (errorDetails) => {
  return errorDetails?.errorCode == 'err_payment'
})

export const getIsMenuOpenedSelector = createSelector(getDialog, (dialog) => dialog.isMenuOpened)
export const dailyRewardModalLastTimeShownSelector = (state: ReduxState) =>
  getItemFormLocalStorageSelector(state, DAILY_REWARD_MODAL_LAST_TIME_SHOWN, false)
export const isSignUpSessionSelector = (state: ReduxState) =>
  getItemFormLocalStorageSelector(state, IS_SIGN_UP_SESSION, false)
