import { createSelector } from '@reduxjs/toolkit'
import { isPast } from 'date-fns'

import type { ReduxState } from 'store'

const getFreeSpins = (state: ReduxState) => state.freeSpins.freeSpins

export const getFreeSpinsSelector = createSelector(getFreeSpins, (freeSpins) =>
  freeSpins.filter((freeSpin) => !isPast(new Date(freeSpin.expireAt)) && freeSpin.rounds > freeSpin.roundsPlayed)
)

export const getFreeSpinsScratchCardsSelector = createSelector(
  getFreeSpinsSelector,
  (_: ReduxState, gameCode?: string) => gameCode,
  (freeSpins, gameCode?) =>
    freeSpins.filter(
      (freeSpin) => freeSpin.products[0].type === 'scratch_card' && freeSpin.products[0].code !== gameCode
    )
)
export const getFreeSpinsSlotGamesSelector = createSelector(
  getFreeSpinsSelector,
  (_: ReduxState, gameCode?: string) => gameCode,
  (freeSpins, gameCode) =>
    freeSpins.filter(
      (freeSpin) => freeSpin.products[0].type !== 'scratch_card' && freeSpin.products[0].code !== gameCode
    )
)
