import getParamFromString from '@patrianna/shared-utils/getParamFromString'
import { trackAnalyticEvent } from '@patrianna-payments/analytics/main'
import { identifyAnalytic } from '@patrianna-payments/analytics/main/events'
import type { AnalyticOptionsArg } from '@patrianna-payments/analytics/main/types'

const URL_FOR_BLOOMREACH_CONFIG = 'https://api.uk.exponea.com'

const gtm = {
  auth: getParamFromString(process.env.GTM_ENV_KEY || '', 'gtm_auth'),
  preview: getParamFromString(process.env.GTM_ENV_KEY || '', 'gtm_preview'),
  containerId: process.env.GTM_KEY,
}

const bloomreachConfig = {
  key: process.env.BLOOMREACH_KEY,
  targetUrl: URL_FOR_BLOOMREACH_CONFIG,
  websitePushID: process.env.WEBSITE_PUSH_ID,
}

const tiktokConfig = {
  key: process.env.TIKTOK_PIXEL_ID,
}

export const analyticConfig = {
  debug: false,
  app: process.env.BRAND_NAME,
  analytics: {
    gtm,
    bloomreach: bloomreachConfig,
    tiktok: tiktokConfig,
  },
}

export type AnalyticOptions = AnalyticOptionsArg<(typeof analyticConfig)['analytics']> & { all?: boolean }

export const defaultAnalyticOptions: AnalyticOptions = {
  all: false,
  gtm: true,
  tiktok: false,
}

export const trackEvent = <T = unknown>(...args: Parameters<typeof trackAnalyticEvent<AnalyticOptions, T>>) =>
  trackAnalyticEvent<(typeof analyticConfig)['analytics'], T>(...args)

export const identify = (...args: Parameters<typeof identifyAnalytic<AnalyticOptions>>) =>
  identifyAnalytic<(typeof analyticConfig)['analytics']>(...args)
