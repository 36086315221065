'use client'

import { useParams, usePathname, useRouter as useNextRouter } from 'next/navigation'
import NProgress from 'nprogress'
import type { ReactNode } from 'react'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react'

import { useAuth } from 'app/context/auth'
import { getLanguageHref } from 'app/utils/getLanguageHref'
import ROUTES from 'temp/routes.json'

type NavigationContextType = {
  pushWithCallback: (path: string, callback?: () => void, language?: string) => void
  goBack: () => void
}

const NavigationContext = createContext<NavigationContextType>(null)

type NavigationProviderProps = {
  children: ReactNode
}

const NavigationProvider = ({ children }: NavigationProviderProps) => {
  const { locale } = useParams()
  const pathname = usePathname()
  const callbacks = useRef<any[]>([])
  const navHistory = useRef<string[]>([])
  const { push, back } = useNextRouter()
  const { isLoggedIn } = useAuth()

  useEffect(() => {
    if (!navHistory.current.includes(pathname) && navHistory.current.length < 2) {
      navHistory.current.push(pathname)
    }

    for (const callback of callbacks.current) {
      callback?.()
      callbacks.current.splice(callbacks.current.indexOf(callback), 1)
    }

    NProgress.done()
  }, [pathname])

  const pushWithCallback = useCallback(
    (path: string, callback?: () => void, language?: string) => {
      callbacks.current = [...callbacks.current, callback]
      const externalHref = path?.startsWith('http')
      const languageHref = getLanguageHref({ href: path, language, locale })

      if (externalHref) {
        push(path)
      } else {
        const nextHref = languageHref === '/' ? ROUTES.INDEX : languageHref

        if (pathname !== nextHref) {
          NProgress.configure({ showSpinner: false }).start()
        }

        push(languageHref)
      }
    },
    [locale, pathname, push]
  )

  const goBack = useCallback(() => {
    const inAppNavigation = navHistory.current.length > 1

    NProgress.configure({ showSpinner: false }).start()

    if (inAppNavigation) {
      back()
    } else {
      isLoggedIn ? push(ROUTES.INDEX) : push(ROUTES.LANDING_PAGE)
    }
  }, [back, isLoggedIn, push])

  const value = useMemo(
    () => ({
      pushWithCallback,
      goBack,
    }),
    [goBack, pushWithCallback]
  )

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>
}

function useRouter() {
  const { pushWithCallback, goBack } = useContext(NavigationContext)
  const nextRouter = useNextRouter()

  if (pushWithCallback === undefined || !goBack) {
    throw new Error('useRouterPush must be used within a NavigationProvider')
  }

  return {
    ...nextRouter,
    push: pushWithCallback,
    back: goBack,
  }
}

export { NavigationContext, NavigationProvider, useRouter }
