import type { GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import { createSelector } from '@reduxjs/toolkit'

import type { ReduxState } from 'store'
import { getActiveCurrencyIdSelector } from 'store/modules/currencies/selectors'
import { getFreeSpinsSelector } from 'store/modules/freeSpins/selectors'
import { getGoldenKeySelector } from 'store/modules/user/selectors'
import { isGoldenKeyGameProduct } from 'utils/games'

const PAUSE_PERIOD = 5
const MINUTES = 60 * 1000

const getSlotGameFlow = (state: ReduxState) => state.slotGameFlow

export const getSweepstakeMinBetSelector = createSelector(
  getSlotGameFlow,
  (slotGameFlow) => slotGameFlow.sweepstakeMinBet || 0.25
)

export const getGoldMinBetSelector = createSelector(getSlotGameFlow, (slotGameFlow) => slotGameFlow.goldMinBet || 50)

export const getActiveGameTagsSelector = createSelector(getSlotGameFlow, (slotGameFlow) => slotGameFlow.activeGameTags)

export const getLowOnCoinsLastDateOpenedSelector = createSelector(
  getSlotGameFlow,
  (slotGameFlow) => slotGameFlow.lowOnCoinsLastDateOpened
)

export const getActiveGameCodeSelector = createSelector(getSlotGameFlow, (slotGameFlow) => slotGameFlow.activeGameCode)

export const showGameNotForScDialogSelector = createSelector(
  getActiveCurrencyIdSelector,
  getGoldenKeySelector,
  (_: ReduxState, currentGame: GameProduct) => currentGame,
  (currency, goldenKey, game) => {
    const isGoldMode = isGoldenKeyGameProduct(game)
    const onlyGoldMode = isGoldMode && currency === 'SC'

    return onlyGoldMode || (!goldenKey && isGoldMode)
  }
)

export const getMinBetSelector = createSelector(
  getActiveCurrencyIdSelector,
  getGoldMinBetSelector,
  getSweepstakeMinBetSelector,
  (activeCurrency, goldMinBet, sweepstakeMinBet) => {
    return activeCurrency === 'GC' ? goldMinBet : sweepstakeMinBet
  }
)

export const getLowOnCoinsDialogDelaySelector = createSelector(
  getLowOnCoinsLastDateOpenedSelector,
  (_: ReduxState, currentTime: number) => currentTime,
  (lastDateOpened, currentTime) => {
    if (lastDateOpened) {
      const differenceInTime = currentTime - new Date(lastDateOpened).getTime()
      const differenceInMinutes = differenceInTime / MINUTES

      return differenceInMinutes >= PAUSE_PERIOD
    }

    return true
  }
)

export const getIsActiveGameHasFreeSpins = createSelector(
  getFreeSpinsSelector,
  getActiveGameCodeSelector,
  getActiveCurrencyIdSelector,
  (freeSpins, activeGameCode, activeCurrency) => {
    return freeSpins.some(
      (freeSpin) =>
        freeSpin.currency === activeCurrency && freeSpin.products.some((game) => game.code === activeGameCode)
    )
  }
)

export const isLiveCasinoGameLaunchedSelector = createSelector(getActiveGameTagsSelector, (gameTags) =>
  gameTags.includes('LIVE')
)
